/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
// @TODO: this is used in oak-about, but i don't think it should be
// @TODO: https://stackoverflow.com/questions/35430336/redirect-users-to-itunes-app-store-or-google-play-store
import React, {
  useEffect,
} from 'react';
import Globals from '../../Globals';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';

const IOS = 'ios';
const ANDROID = 'android';

const StoreLink = (props) => {
  const _getOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return IOS;
    }

    return null;
  };

  const _redirectToStore = (appKey) => {
    const os = _getOS();
    let href;
    if (os) {
      href = Globals.apps[appKey][os].storeUrl;
    } else if (appKey === 'tibbits' || appKey === 'theOne' || appKey === 'wrinkly') { // @TODO: don't hardcode this
      href = Globals.apps[appKey].websiteUrl;
    }

    window.location.href = href || 'https://oakwoodsc.com';
  };

  useEffect(() => {
    _redirectToStore(props.match.params.appKey);
  }, []);

  return (
    <div>
      <p>
        Redirecting...
      </p>
      <p>If you are not automatically redirected within 10 seconds, please <Link to='/'>click here</Link> to find the download link to the app for which you are searching.</p>
    </div>
  );
};

StoreLink.propTypes = {
  match: PropTypes.object.isRequired,
};

export default StoreLink;
