/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    color: Globals.colors.light,
    backgroundColor: Globals.colors.dark,
    paddingTop: 10,
  },
  fixedHeader: {
    position: 'fixed',
    top: -10, // @NOTE: top padding on header image
  },
  tabsAndContactWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tabsWrapper: {
    [Globals.mediaQueries.xs]: {
      display: 'none',
    },
  },
};

export default styles;
