/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import styles from './styles';

const ProjectCard = (props) => {
  const handleClick = (linkType) => {
    ReactGA.event({
      category: 'Link clicked',
      action: `${linkType} visited`,
    });
  };

  return (
    <div style={styles.container}>
      <Card>
        {props.comingSoon && (
          <span style={styles.comingSoonBadge}>
            SUMMER 2022
          </span>
        )}
        <CardHeader
          title={props.name}
          subheader={props.tagline}
        />
        <CardContent style={styles.cardBody}>
          <img style={styles.image} src={props.imgUrl} alt={props.name} />
          <span style={styles.description}>
            {props.children}
          </span>
        </CardContent>
        <CardActions>
          {(props.url &&
            <Button
              onClick={() => handleClick('website')}
              href={props.url}
              color={props.url ? 'primary' : 'accent'}
            >
              Website
            </Button>
          )}
        </CardActions>
        {(props.iosLink ||
          props.androidLink ||
          props.webLink) &&
          <hr style={styles.hr} />
        }
        <CardActions style={styles.storeButtonsWrapper}>
          {props.iosLink &&
            <a
              onClick={() => handleClick('ios')}
              href={props.iosLink}
            >
              <img alt='iOS download' src='/img/apple_app_store_icon.svg' />
            </a>
          }
          {props.androidLink &&
            <a
              onClick={() => handleClick('android')}
              href={props.androidLink}
            >
              <img alt='Android download' src='/img/google_play_store_icon.png' />
            </a>
          }
          {props.webLink &&
            <a
              onClick={() => handleClick()}
              href={props.webLink}
            >
              <img alt='Web download' src='/img/web_app_icon.png' />
            </a>
          }
        </CardActions>
      </Card>
    </div>
  );
};

ProjectCard.propTypes = {
  name: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,

  iosLink: PropTypes.string,
  androidLink: PropTypes.string,
  webLink: PropTypes.string,
  url: PropTypes.string,
};

export default ProjectCard;
