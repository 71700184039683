/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const commonStyles = {
  sectionText: {
    textAlign: 'left',
    fontSize: 'large',
    maxWidth: 700,
    paddingLeft: 16,
    paddingRight: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

export default commonStyles;
