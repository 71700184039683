/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import ContactButton from '../ContactButton';
import Developer from '../Developer';
import Radium from 'radium';
import React from 'react';
import commonStyles from '../../commonStyles';
import styles from './styles';

const About = () => {
  return (
    <div
      id='about_us'
      style={styles.container}
    >
      <h1>Our Team</h1>
      <p style={commonStyles.sectionText}>Oakwood Software Consulting, Inc. is a Christ-centered organization built on biblical principles.  As such, we strive to honor God in all that we do, act with integrity to deliver to our customers, and work to the best of our abilities.  We are passionate about our projects and take pride in our products.</p>

      <div style={styles.developersWrapper}>
        <Developer
          name="Ryan O'Dowd"
          title={<span>{'President & CEO'}<br />Mobile Developer<br />Back-end Engineer</span>}
          imgSrc='img/rodowd.jpg'
          linkedInUrl='https://linkedin.com/in/rodowd'
          quote={`"It's not enough to think outside the box; you have to believe that what's outside the box is possible."`}
          quoteAuthor="Ryan O'Dowd"
        />
        <Developer
          name='Brandon Trautmann'
          title='Mobile Developer'
          imgSrc='img/btraut.jpg'
          linkedInUrl='https://www.linkedin.com/in/brandontrautmann/'
          quote={'"It is not enough for code to work."'}
          quoteAuthor='Uncle Bob'
        />
      </div>

      <div style={styles.contactButtonWrapper}>
        <ContactButton position='about section' />
      </div>
    </div>
  );
};

export default Radium(About);
