/**
 * Ryan O'Dowd
 * 2018-02-05
 * © Copyright 2018 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    position: 'relative',
  },
  image: {
    width: 120,
    height: 120,
    objectFit: 'contain',
    padding: 8,
  },
  cardBody: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  description: {
    textAlign: 'left',
    margin: 0,
  },
  hr: {
    marginLeft: 16,
    marginRight: 16,
  },
  storeButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    textAlign: 'left',
  },
  dialogText: {
    color: Globals.colors.dark,
    fontSize: '1rem',
  },
  dialogLink: {
    color: Globals.colors.primary,
  },
  comingSoonBadge: {
    position: 'absolute',
    top: 2,
    left: -8,
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.32), 0 0 0 1px rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ff3344',
    fontSize: 12,
    color: 'white',
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 4,
    transform: 'rotate(-15deg)',
  },
};

export default styles;
