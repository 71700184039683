/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const Globals = {
  colors: {
    primary: '#00BCD4',
    accent: '#CDDC39',
    error: '#FF5252',

    dark: '#212121',
    light: '#FFFFFF',
    gray: '#757575',
    disabled: '#bdbdbd',

    bgLight: '#EEEEEE',
  },

  apps: {
    tibbits: {
      name: 'Tibbits',
      ios: {
        storeUrl: 'https://itunes.apple.com/us/app/id1332548860?mt=8&ls=1',
      },
      android: {
        storeUrl: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.tibbits',
      },
      websiteUrl: 'https://tibbits.oakwoodsc.com',
    },
    wrinkly: {
      name: 'Wrinkly',
      ios: {
        storeUrl: 'https://itunes.apple.com/us/app/id1615885499?mt=8&ls=1',
      },
      android: {
        storeUrl: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.wrinkly',
      },
      websiteUrl: 'https://wrink.ly',
    },
    theOne: {
      name: 'The One',
      ios: {
        storeUrl: 'https://itunes.apple.com/us/app/id1441223102?mt=8&ls=1',
      },
      android: {
        // @TODO: storeUrl: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.theOne',
        storeUrl: '/the-one-release.apk',
      },
      websiteUrl: 'https://theone.oakwoodsc.com',
    },
  },

  isMobile: window.innerWidth <= 960,
  mediaQueries: {
    xxs: '@media (max-width: 420px)',
    xs: '@media (max-width: 600px)',
    sm: '@media (max-width: 960px)',
    md: '@media (max-width: 1280px)',
    lg: '@media (max-width: 1920px)',
    // xl > 1920
  },
};

export default Globals;
