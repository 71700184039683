/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
const styles = {
  buttonWrapper: {
    margin: 8,
  },
};

export default styles;
