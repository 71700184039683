/*
 * Ryan O'Dowd
 * 2019-08-06
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import React, {
  useEffect,
} from 'react';
import ReactGA from 'react-ga4';
import styles from './styles';

const EmailSignInContinueUrl = (props) => {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <div>
      <h1>Unrecognized Device</h1>
      <p style={styles.leftAlign}>{"It looks like you're trying to sign in, but we don't recognize this device."}</p>
      <p style={styles.leftAlign}>Please be sure to install the app and click the sign-in link from the same device.  (Sign in can be completed only if the email is opened on the same device on which the app is installed.)</p>
      <p style={styles.leftAlign}>If you have not recently attempted to sign in, you may safely ignore this message.</p>
    </div>
  );
};

export default EmailSignInContinueUrl;
