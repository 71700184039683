/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Tab,
  Tabs,
} from '@material-ui/core';
import ContactButton from '../ContactButton';
import Legal from './Legal';
import {
  Link,
} from 'react-router-dom';
import Radium from 'radium';
import ReactGA from 'react-ga4';
import styles from './styles';

const Header = (props) => {
  const [_selectedTab, setSelectedTab] = useState('our_work');
  const [_headerShrunk, setHeaderShrunk] = useState(false);

  const handleScroll = (event) => {
    /* @TODO: should i be sending an event instead?? */
    let scrollTop = window.pageYOffset;
    let tabScrollThreshold = scrollTop + (window.innerHeight / 2);

    setHeaderShrunk(scrollTop >= (127 - 52)); /* @TODO: abstract number (unshrunk - shrunk) */

    let currentTab = null;
    if (document.getElementById('about_us') && document.getElementById('our_work')) {
      // this isn't the donate-complete page or 404 page
      if (tabScrollThreshold >= document.getElementById('about_us').offsetTop) {
        currentTab = 'about_us';
      } else if (tabScrollThreshold >= document.getElementById('our_work').offsetTop) {
        currentTab = 'our_work';
      }
    }

    setSelectedTab(currentTab); /* @TODO: enum? */
  }

  const handleTabClick = (value) => {
    ReactGA.event({
      category: 'Tab clicked',
      action: `${value} tab visited`,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    if (document.getElementById('about_us') && document.getElementById('our_work')) {
      // this isn't the donate-complete page or 404 page
      setSelectedTab('our_work');
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{...styles.container, ...(_headerShrunk ? styles.fixedHeader : {})}}>
      {!_headerShrunk && <Link to='/'><img src='/img/logo_with_text.png' alt='logo' /></Link>}
      <div style={styles.tabsAndContactWrapper}>
        <div style={styles.tabsWrapper}>
          <Tabs
            value={_selectedTab}
            centered
          >
            <Tab value='our_work' label='Projects' href='/#our_work' onClick={() => handleTabClick('our_work')} />
            <Tab value='about_us' label='Our Team' href='/#about_us' onClick={() => handleTabClick('about_us')} />
          </Tabs>
        </div>
        <ContactButton position='header' />
      </div>
      <Legal isOpen={window.location.hash === '#terms_and_privacy'} />
    </div>
  );
};

export default Radium(Header);
