/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import {
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import {
  cyan,
  lime,
  red,
} from '@material-ui/core/colors';
import EmailSignInContinueUrl from '../EmailSignInContinueUrl';
import Error404 from '../Errors/Error404';
// @TODO: import Footer from '../Footer';
import Header from '../Header';
import Home from '../Home';
import React from 'react';
import ReactGA from 'react-ga4';
import StoreLink from '../StoreLink';
import {
  StyleRoot,
} from 'radium';
import styles from './styles';

ReactGA.initialize('378802836');

const theme = createTheme({
  palette: {
    primary: cyan,
    secondary: lime,
    error: red,
  },
  typography: {
    fontFamily: 'Lato',
  },
});

class App extends React.Component {
  render() {
    return (
      <StyleRoot>
        <MuiThemeProvider theme={theme}>
          <div style={styles.maxWidthContent}>
            <BrowserRouter>
              <React.Fragment>
                <Header />
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route path='/store-link/:appKey' component={StoreLink} />
                  <Route exact path='/email-sign-in-continue-url' component={EmailSignInContinueUrl} />
                  <Route component={Error404} />
                </Switch>
              </React.Fragment>
            </BrowserRouter>
          </div>
          {/* @TODO:
          <Footer />
          */}
        </MuiThemeProvider>
      </StyleRoot>
    );
  }
}

export default App;
