/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
const styles = {
  container: {
    marginTop: 64,
    paddingBottom: 60,
  },
  projectsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32,
  },
  cardWrapper: {
    maxWidth: 300,
    margin: 16,
    alignSelf: 'flex-start',
  },
  list: {
    paddingInlineStart: 16,
  },
};

export default styles;
