/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import styles from './styles';

const ContactButton = (props) => {
  return (
    <Button
      style={styles.buttonWrapper}
      variant='contained'
      color='primary'
      href='https://forms.gle/g9ftHuLs9hpKyNfV9'
      onClick={() => {
        ReactGA.event({
          category: 'Contact Us button clicked',
          action: `button in ${props.position}`,
        });
      }}
    >
      Contact Us
    </Button>
  );
};

ContactButton.propTypes = {
  position: PropTypes.string.isRequired,
};

export default ContactButton;
