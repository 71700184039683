/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    marginBottom: 64,
  },
  contactButtonWrapper: {
    marginTop: 40,
  },
  developersWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [Globals.mediaQueries.xxs]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    justifyContent: 'center',
  },
};

export default styles;
