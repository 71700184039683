/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';
import {
  Grid,
} from '@material-ui/core';
import ProjectCard from '../ProjectCard';
import React from 'react';
import styles from './styles';

const OurWork = () => {
  return (
    <div id='our_work' style={styles.container}>
      <h1>Featured Projects</h1>
      <Grid container style={styles.projectsWrapper}>
        <Grid item xs={12} sm={4} style={styles.cardWrapper}>
          <ProjectCard
            name='Wrinkly'
            url='https://wrink.ly'
            tagline='Bible memory.'
            imgUrl='/img/wrinkly.png'
            iosLink={Globals.apps.wrinkly.ios.storeUrl}
            androidLink={Globals.apps.wrinkly.android.storeUrl}
            // @TODO: comingSoon={true}
          >
            <p style={styles.description}>Wrinkly aims to make Scripture memory as effective and engaging as possible.  Using multiple strategies for learning and memorization, Wrinkly is a fun and powerful tool.</p>
          </ProjectCard>
        </Grid>
        <Grid item xs={12} sm={4} style={styles.cardWrapper}>
          <ProjectCard
            name='Tibbits'
            url='https://tibbits.oakwoodsc.com'
            tagline='Remember names and other tidbits.'
            imgUrl='/img/tibbits.png'
            iosLink={Globals.apps.tibbits.ios.storeUrl}
            androidLink={Globals.apps.tibbits.android.storeUrl}
          >
            <p style={styles.description}>{"Be more mindful of new relationships and strengthen existing ones. Quickly jot down a person's name and a few notes about your conversation, and assign labels to stay organized. Need to pull up a person's info on the fly? Search by name, label, or notes to find someone lickety-split!"}</p>
          </ProjectCard>
        </Grid>
        <Grid item xs={12} sm={4} style={styles.cardWrapper}>
          <ProjectCard
            name='The One'
            url='https://theone.oakwoodsc.com'
            tagline="World's best dating app."
            imgUrl='/img/the_one.png'
            iosLink={Globals.apps.theOne.ios.storeUrl}
          >
            <p style={styles.description}>{"Tired of dating apps that don't work?"}</p>
            <ul style={styles.list}>
              <li>{'Enjoy a truly one-of-a-kind dating experience!'}</li>
              <li>{"Find a real relationship. No hooking up here; when we say we'll show you the one, we mean it!"}</li>
              <li>{'The most highly curated and individual profiles of any dating app.'}</li>
              <li>{`"I've been praying for The One for years!" -#1 fan of The One`}</li>
              <li>{'Discover The One today!'}</li>
            </ul>
          </ProjectCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurWork;
