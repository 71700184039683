/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  minorTitle: {
    marginBottom: 0,
  },
  dialog: {
    fontWeight: '100',
    textAlign: 'left',
  },
  bold: {
    fontWeigth: 'bold',
  },
  copyright: {
    textAlign: 'right',
  },
};

export default styles;
