/*
 * Ryan O'Dowd
 * 2019-05-31
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: 120,
  },
  image: {
    width: 300,
  },
  noTopMargin: {
    marginTop: 0,
  },
};

export default styles;
