/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  maxWidthContent: {
    width: '100%',
    maxWidth: 1000,
    minHeight: 'calc(100vh - 127px - 35px)',
    margin: 'auto',
    paddingTop: 127, // @NOTE: height of header
  },
};

export default styles;
