/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    width: '100%',
    maxWidth: 182,
    margin: 16,
  },
  card: {
    width: '100%',
    textAlign: 'left',
  },
  name: {
    margin: 0,
    fontWeight: '600',
    fontSize: 16,
  },
  title: {
    margin: 0,
    marginBottom: 0,
    fontSize: 16,
    fontWeight: '100',
  },
  quote: {
    fontStyle: 'italic',
    fontSize: 14,
    lineHeight: '1.1rem',
  },
  image: {
    width: 150,
    height: 150,
    margin: 16,
  },
};

export default styles;
