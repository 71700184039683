/*
 * Ryan O'Dowd
 * 2019-08-06
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  leftAlign: {
    textAlign: 'left',
  },
};

export default styles;
