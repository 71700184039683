/*
 * Ryan O'Dowd
 * 2019-05-31
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Link,
} from 'react-router-dom';
import React from 'react';
import styles from './styles';

const image = '/img/oakwood.png';

const Error404 = () => {
  return (
    <div style={styles.container}>
      <img
        src={image}
        style={styles.image}
        alt=''
      />
      <h1>{"Hm...we can't find the requested page"}</h1>
      <p style={styles.noTopMargin}>Sorry about that!  Please try a link above or visit our <Link to='/'>home page</Link>.</p>
    </div>
  );
};

export default Error404;
