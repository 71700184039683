/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import styles from './styles';

const Developer = (props) => {
  const handleClick = (developerName) => {
    ReactGA.event({
      category: 'LinkedIn button clicked',
      action: `${developerName}'s page visited`,
    });
  };

  return (
    <div style={styles.container}>
      <Card style={styles.card}>
        <CardMedia
          style={styles.image}
          image={props.imgSrc}
          title={props.name}
        />
        <CardContent>
          <h2 style={styles.name}>{props.name}</h2>
          <h3 style={styles.title}>{props.title}</h3>
          {/* @TODO:
          <p style={styles.quote}>
            {props.quote}<br />
            --{props.quoteAuthor}
          </p>
          */}
        </CardContent>
        <CardActions>
          <Button
            color='primary'
            href={props.linkedInUrl}
            onClick={() => handleClick(props.name)}
          >
            LinkedIn
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

Developer.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  quoteAuthor: PropTypes.string.isRequired,
  linkedInUrl: PropTypes.string.isRequired,
};

export default Developer;
