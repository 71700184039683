/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import App from './components/App';
import Globals from './Globals';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  createGlobalStyle,
} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 100;
    line-height: 1.4rem;
    text-align: center;
    background-color: ${Globals.colors.light};
  }
`;

const rootJsx = (
  <React.Fragment>
    <GlobalStyle />
    <App />
  </React.Fragment>
);

ReactDOM.render(rootJsx, document.getElementById('root'));
