/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import React, {
  useEffect,
} from 'react';
import About from '../About';
import OurWork from '../OurWork';
import ReactGA from 'react-ga4';

const Home = (props) => {
  useEffect(() => {
    ReactGA.send('pageview');
  });

  return (
    <React.Fragment>
      <OurWork />
      <About />
    </React.Fragment>
  );
};

export default Home;
